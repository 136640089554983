import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Head from 'next/head'
import { UPVEST_EMAIL } from '@upvestcz/common/constants'
import { replace } from '@upvestcz/common/routing'
import {
    Button,
    Divider,
    FormControl,
    FormErrorMessage,
    Input,
    FormLabel,
    Form,
    Flex,
    Heading,
    GoogleAuthButton,
    SimpleGrid,
} from '@upvestcz/shared-components'
import { getFieldError } from '@upvestcz/common/forms'
import { login } from '../lib/api'
import { useToast } from '../lib/toast'
import { useAfterAuth } from '../lib/auth'

const Login = () => {
    const afterAuth = useAfterAuth()
    const addToast = useToast()
    const [isLoading, setIsLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: yup.object().shape({
            email: yup
                .string()
                .required('Vyplňte prosím Váš e-mail')
                .email('Vyplňte prosím platný e-mail'),
            password: yup.string().required('Vyplňte prosím Vaše heslo'),
        }),
        async onSubmit(values) {
            setIsLoading(true)

            try {
                const loginResponse = await login({
                    username: values.email,
                    password: values.password,
                })

                if (!loginResponse.data) {
                    throw new Error('No data in login response.')
                }

                const { profile, ...token } = loginResponse.data

                await afterAuth({
                    profile,
                    token,
                })
                // redirect is made in afterAuth
            } catch (err) {
                formik.setFieldValue('password', '')

                if (err instanceof Response) {
                    if (err.status === 403) {
                        addToast({
                            type: 'error',
                            message: 'Chybný e-mail nebo heslo',
                        })
                    } else if (err.status === 429) {
                        addToast({
                            type: 'error',
                            message: `Váš účet byl dočasně zablokován kvůli příliš vysokému počtu nesprávného zadání hesla. Kontaktuje prosím podporu Upvest emailem na ${UPVEST_EMAIL}.`,
                            timeout: 20000,
                        })
                    } else if (err.status === 422) {
                        addToast({
                            type: 'error',
                            message: 'Již zde máte účet registrovaný přes Google',
                        })
                    } else {
                        addToast({
                            type: 'error',
                            message: 'Při přihlašování se vyskytla neočekávaná chyba',
                        })
                    }
                } else {
                    addToast({
                        type: 'error',
                        message: 'Při přihlašování se vyskytla neočekávaná chyba',
                    })
                }
                setIsLoading(false)
            }
        },
    })

    return (
        <>
            <Head>
                <title>Přihlášení</title>
                <meta
                    name="description"
                    content="Přihlášení klienta k investiční platformě Upvest."
                />
                <meta name="robots" content="noindex,nofollow" />
            </Head>
            <Flex
                flexDirection="column"
                alignItems="center"
                maxWidth="400px"
                margin="200px auto 0"
                h="100%"
            >
                <Heading size="2xl" mb={8}>
                    Přihlásit se
                </Heading>
                <Form onSubmit={formik.handleSubmit} w="100%">
                    <SimpleGrid gap={4}>
                        <FormControl isInvalid={!!getFieldError(formik, 'email')}>
                            <FormLabel>Email</FormLabel>
                            <Input
                                value={formik.values.email}
                                type="email"
                                name="email"
                                onChange={formik.handleChange}
                                isInvalid={!!getFieldError(formik, 'email')}
                            />
                            <FormErrorMessage>{getFieldError(formik, 'email')}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!getFieldError(formik, 'password')}>
                            <FormLabel>Password</FormLabel>
                            <Input
                                value={formik.values.password}
                                type="password"
                                name="password"
                                onChange={formik.handleChange}
                                isInvalid={!!getFieldError(formik, 'password')}
                            />
                            <FormErrorMessage>{getFieldError(formik, 'password')}</FormErrorMessage>
                        </FormControl>
                        <Button
                            isLoading={isLoading}
                            type="submit"
                            data-test-id="button-submit"
                            variant="solidWhite"
                            w="100%"
                        >
                            Přihlásit se
                        </Button>
                    </SimpleGrid>
                </Form>
                <Divider />

                <GoogleAuthButton relativeRedirectUri="/login-callback">
                    Přihlásit se přes Google
                </GoogleAuthButton>
            </Flex>
        </>
    )
}

Login.getInitialProps = (ctx: TODO) => {
    const { auth } = ctx

    if (auth.loggedIn) {
        return replace({ ctx, location: '/' })
    }

    return {}
}

export default Login
