import { FormikProps, getIn } from 'formik'
import { ensureArray, OneOrArrayOf } from './ts-utils'

// can't simply set value (https://github.com/facebook/react/issues/10135#issuecomment-314441175)
export function setNativeValue(element: HTMLInputElement, value: unknown) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')!.set
    const prototype = Object.getPrototypeOf(element)
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value')!.set

    if (valueSetter && prototypeValueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter.call(element, value)
    } else {
        valueSetter!.call(element, value)
    }
}

export function fireOnChange(target: HTMLElement) {
    const event = new Event('input', { bubbles: true })
    target.dispatchEvent(event)
}

export const getFieldError = <Values extends Record<string, unknown>>(
    formik: Pick<FormikProps<Values>, 'errors' | 'touched'>,
    fieldName: string,
) => {
    const error: string | string[] = getIn(formik.errors, fieldName)
    const touch: boolean = getIn(formik.touched, fieldName)
    return touch && error ? error : undefined
}

export const useFormikCheckAll = <Values extends Record<string, unknown>>(
    maybeInstancesArray: OneOrArrayOf<{
        formik: Pick<FormikProps<Values>, 'setFieldValue' | 'values'>
        values: (keyof Values & string)[]
    }>,
) => {
    const instancesArray = ensureArray(maybeInstancesArray)
    const setAll = (checked: boolean) => {
        instancesArray.forEach(({ formik, values }) => {
            values.forEach(value => formik.setFieldValue(value, checked))
        })
    }
    const allChecked = instancesArray.every(({ formik, values }) =>
        values.every(value => formik.values[value]),
    )

    const indeterminate =
        instancesArray.some(({ formik, values }) => values.some(value => formik.values[value])) &&
        !allChecked

    return { setAll, indeterminate, allChecked }
}

export const touchAllFields = (formik: TODO, fieldKeys: string[]) => {
    fieldKeys.forEach(fieldKey => {
        if (
            Array.isArray(formik.values[fieldKey]) &&
            formik.values[fieldKey].length > 0 &&
            typeof formik.values[fieldKey][0] === 'object' &&
            formik.values[fieldKey][0] !== null
        ) {
            const objectKeys = Object.keys(formik.values[fieldKey][0])

            const preparedKeys = formik.values[fieldKey].reduce(
                (acc: string[], _: TODO, index: number) =>
                    acc.concat(objectKeys.map(key => `${fieldKey}.${index}.${key}`)),
                [],
            )

            touchAllFields(formik, preparedKeys)
        } else {
            formik.setFieldTouched(fieldKey)
        }
    })
}
